import { SET_CONTACT_INFO } from '../actions/types';

const contactInfo = (state = [], { type, payload }) => {
	switch (type) {
		case SET_CONTACT_INFO:
			return payload || state;
		default:
			return state;
	}
};

export default contactInfo;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { setClassNames } from 'ddc-classnames-js';
import { usePrefs, useLabels, useRequestData } from 'wsm-common-data';
import { Cta } from 'wsm-contact-ctas';
import Address from 'wsm-contact-address';
import { getMapLink } from 'wsm-contact-transformer';

const Widget = () => {
	const { dealerInfo, contactInfo } = useSelector((state) => state);
	const {
		mapButtonStyle,
		showCallLabel,
		mapButtonSize,
		mapButtonLayout,
		callButtonLayout,
		callButtonStyle,
		callButtonSize,
		addressClasses,
		displayAddressAbovePhoneNumbers,
		showMapLink,
		showMapLinkIcon,
		mapLinkLocation,
		enableAddressBackground,
		callButtonListClasses,
		callButtonListItemMargin,
		showDealershipName,
		showAddressIcon,
		showContactIcon,
		displayAddressInline
	} = usePrefs();
	const labels = useLabels();
	const { widgetName, windowId } = useRequestData();
	const displayMapLink = showMapLink === 'true';
	const [mapLink, setMapLink] = useState(
		getMapLink(displayMapLink, dealerInfo)
	);

	useEffect(() => {
		setMapLink(
			getMapLink(displayMapLink, dealerInfo, window.navigator.userAgent)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // No dependencies included to only trigger effect once

	const ctaProps = {
		labels,
		contactInfo,
		widgetName,
		windowId,
		mapLink: mapLink && mapLinkLocation === 'phone section' ? mapLink : '',
		accountId: dealerInfo.accountId,
		ctaPrefs: {
			mapButtonStyle,
			mapButtonSize,
			mapButtonLayout,
			callButtonLayout,
			callButtonStyle,
			callButtonSize,
			callButtonListClasses,
			callButtonListItemMargin
		},
		ctaLabels: {
			CALL: labels.get('CALL'),
			GET_DIRECTIONS: labels.get('GET_DIRECTIONS')
		},
		showCallLabel: showCallLabel === 'true',
		showContactIcon: showContactIcon === 'true',
		showMapLinkIcon: showMapLinkIcon === 'true'
	};

	const addressElementClasses = [
		...addressClasses.split(' '),
		...(enableAddressBackground === 'true' ? ['text-black', 'my-3'] : [])
	];

	const addressProps = {
		...dealerInfo,
		addressClasses: setClassNames(addressElementClasses) || '',
		showDealershipName: showDealershipName === 'true',
		showAddressIcon: showAddressIcon === 'true',
		isAddressInline: displayAddressInline === 'true',
		// For offsite inventory when mapLinkLocation is set to 'address section'
		// we override the vehicle's website URL with the directions link
		...(mapLink &&
			mapLinkLocation === 'address section' && {
				url: {
					link: mapLink,
					text: ctaProps.ctaLabels.GET_DIRECTIONS
				}
			})
	};

	const addressMarkup =
		enableAddressBackground === 'true' ? (
			<div
				className="bg-location bg-neutral-0 d-flex border p-4 mx-4 mb-4"
				data-testid="address-background"
			>
				<div className="d-sm-inline mx-4">
					<i
						className="ddc-icon ddc-icon-map ddc-font-size-xlarge text-black"
						aria-hidden="true"
					/>
				</div>
				<Address {...addressProps} />
			</div>
		) : (
			<Address {...addressProps} />
		);

	const markup =
		displayAddressAbovePhoneNumbers === 'true' ? (
			<React.Fragment>
				{addressMarkup}
				<Cta {...ctaProps} />
			</React.Fragment>
		) : (
			<React.Fragment>
				<Cta {...ctaProps} />
				{addressMarkup}
			</React.Fragment>
		);

	return markup;
};

export default Widget;

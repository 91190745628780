import { SET_DEALER_INFO } from '../actions/types';

const dealerInfo = (state = {}, { type, payload }) => {
	switch (type) {
		case SET_DEALER_INFO:
			return payload || state;
		default:
			return state;
	}
};

export default dealerInfo;
